const product_ingredients_class = '.product-ingredients';
    productsTabsButtons = document.querySelectorAll(`${product_ingredients_class} ${product_ingredients_class}__tabs-categories button`),
    productsTabs = document.querySelectorAll(`${product_ingredients_class} ${product_ingredients_class}__tabs-content-text`),
    product_active_class = 'active';

productsTabsButtons.forEach( (btn) => {
    btn.addEventListener('click', () => {
        const selectedTabID = btn.getAttribute('data-content-id');
        productsTabsButtons.forEach((button) => {
            button.classList.remove(product_active_class);
        });
        productsTabs.forEach((card) => {
            card.classList.remove(product_active_class);
        });
        
        btn.classList.add(product_active_class);
        productsTabs[selectedTabID].classList.add(product_active_class);        
    })
})